@import "../../../../app/App";

.account-popover__overlay {
  .ant-popover-content {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
    border-radius: 3px;

    .ant-popover-title {
      border-bottom: none;
    }
  }
}

.account-popover__container {
  padding: 10px;

  .account-popover__user {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .account-popover__user-details {
      margin-left: 20px;

      h2 {
        color: $heading-color;
      }

      span {
        color: $normal-grey-color;
      }
    }
  }

  .account-popover__divider {
    margin: 12px 0;

    &.no-margin {
      margin: 0;
    }
  }

  .account-popover__item {
    display: flex;
    align-items: center;

    div {
      display: inline-flex;
      align-items: center;
    }

    &.link {
      cursor: pointer;

      & > a {
        display: contents;
      }

      .account-popover__item-label {
        cursor: inherit;
      }
    }

    .account-popover__item-icon {
      margin-right: 5px;
      color: #acb8b9;
    }

    .account-popover__item-label {
      margin: 0 8px 0 3px;
      font-weight: 500;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.account-popover__logout-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    padding-left: 10px;
  }
}

.account-popover__version {
  display: flex;
  justify-content: flex-end;
  color: lightgrey;
  margin-top: 15px;
  font-size: 10px;
}

.account-popover__flex-wrapper {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
