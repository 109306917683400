@import "../../../app/App";

.list-item-container {
  .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px;
    color: #343a40;

    .list-item-meta {
      display: flex;
      flex: 1 1;
      align-items: center;
      max-width: 100%;

      .ant-list-item-meta-avatar {
        margin-right: 16px;
      }
    }

    h4 {
      font-weight: 500;
    }
  }

  .list-item-actions {
    flex-direction: column;
  }
}

.multiple-annotations {
  margin-top: -38px;
  margin-left: 13.5px;
  width: 100%;

  ul {
    list-style: none;

    &:before {
      width: 2px;
      height: 100%;
      background: $group-block-list-item-text-color;
      position: absolute;
      left: -12px;
      top: 50%;
    }

    li {
      &:first-child {
        margin-top: 20px;
      }

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      color: $white-color;
      margin-left: 55px;
      width: 100%;
      padding: 0 95px 0 0;

      i.bi-trash {
        transition: opacity 0.3s ease-in;
        opacity: 0.7;
        color: $group-block-list-item-text-color;

        &:hover {
          transition: opacity 0.3s ease-out;
          opacity: 1;
          color: $btn-danger-bg;
        }
      }
    }

    .li__dot {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      left: -31px;
      top: 50%;
      transform: translateY(-40%);
    }

    li:not(:last-child):after {
      content: "";
      width: 2px;
      height: 100%;
      background: $group-block-list-item-text-color;
      position: absolute;
      left: -27px;
      top: 0;
    }

    li:last-child:after {
      content: "";
      width: 2px;
      height: 50%;
      background: $group-block-list-item-text-color;
      position: absolute;
      left: -27px;
      top: 0;
    }

    .entity-annotation__annotation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      div.hover-icon {
        width: 40px;
        display: flex;
        justify-content: flex-end;

        .edit {
          &:hover {
            opacity: 1;
          }
        }

        .remove {
          margin-left: 12px;

          &:hover {
            color: $error-color;
            opacity: 1;
          }
        }

        i {
          z-index: 3;
          width: 14px;
          height: 14px;
          font-size: 14px;

          transition: opacity 0.3s ease-in;
          color: rgba($group-block-list-item-text-color, 0%);
          opacity: 0;
        }
      }

      &:hover {
        .hover-icon {
          i {
            transition: opacity 0.3s ease-out;
            color: $white-color;
            opacity: 0.6;
          }

          svg.alert-circle {
            transition: opacity 0.3s ease-out;
            color: $btn-danger-bg;
            opacity: 1;
          }
        }
      }
    }
  }

  .error-text {
    font-size: $font-size-sm;
    font-weight: $font-weight-light;
    color: $error-color;
    margin-top: $margin-xs;
    margin-left: calc($margin-lg * 3 - $margin-xs);
    padding-right: calc($padding-xl * 3);
  }
}

.edit-entity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: $margin-md;
  margin-top: 10px;
  min-height: 30px;
  width: 350px;

  input {
    border-color: $primary-color !important;
    color: $white-color !important;
  }

  .ant-input-number.ant-input-number-sm {
    background-color: transparent;

    .ant-input-number-handler-wrap {
      border: transparent;
      display: none;

      .ant-input-number-input {
        background-color: transparent;

        input {
          background-color: transparent;
        }
      }

      .ant-input-number-handler {
        background-color: $dark-grey-color;
        border: transparent;
      }
    }

    .ant-input-number-input-wrap {
      color: $white-color;
    }
  }

  .date-picker-wrapper {
    width: 100%;

    .ant-picker {
      .ant-picker-input {
        height: 24px;

        input {
          font-size: $font-size-xs;
          color: $white-color;
        }

        span.ant-picker-clear {
          background-color: $dark-grey-color;
        }

        span.ant-picker-suffix {
          background-color: transparent;

          svg {
            path {
              fill: $white-color;
            }
          }
        }
      }
    }
  }

  .ant-input,
  .ant-input-number.ant-input-number-sm,
  .ant-picker {
    width: 70%;
    font-size: $font-size-xs;
    background-color: transparent;
    color: $white-color;
  }

  .btn-approve-entity {
    margin-left: $margin-xs;
  }

  .edit-entity-actions {
    display: flex;
    justify-content: flex-end;
    margin-right: -$margin-md;
    margin-top: -25px;
    align-items: center;

    i.cancel,
    i.submit {
      padding: 10px;
      transition: opacity 0.2s ease-in;
      color: $group-block-list-item-text-color;
      opacity: 1;
      font-size: 22px;
    }

    i.cancel {
      margin-right: $margin-xs;

      width: 18px;
      height: 18px;

      &:hover {
        color: $btn-danger-bg;
      }
    }

    i.submit {
      width: 22px;
      height: 22px;

      &:hover {
        color: $primary-color;
      }
    }
  }
}

.entity-validation__container {
  display: flex;
  align-items: center;
  color: $normal-grey-color;
  margin-top: 8px;
  background-color: transparent !important;

  &.error-status {
    color: $error-color;
  }

  &.warning-status {
    color: $warning-color;
  }

  &.info-status {
    color: $info-color;
  }

  .entity-validation__message {
    margin-left: 5px;
  }
}

.entity-visual__container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  .entity-visual__title {
    font-size: $font-size-sm;
    color: $group-block-list-item-text-color;
  }

  .entity-visual__element {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    margin-top: 2px;
    font-weight: $font-weight-semi-bold;

    &.requires-attention {
      color: $warning-color;
    }

    &.invalid {
      color: $error-color;
    }
  }

  .entity-visual__element_labeled {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 320px;
    margin-top: 2px;
    font-weight: $font-weight-semi-bold;

    &.requires-attention {
      color: $warning-color;
    }
  }

  .entity-visual__seperator {
    font-size: 14px;
    margin: 0 5px;
  }
}

.entity {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}


