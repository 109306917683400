@import "../../App";

$padding: 25px;
$padding-total: $padding*2;

.detail-container {
  border-radius: $border-radius-base;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100vh - $layout-header-height);
  width: calc(100vw - $side-bar-width);

  .annotator-container {
    display: flex;
    flex-direction: row;
  }

  .annotation-table-controls-container {
    background-color: $dark-grey-color;
    z-index: 1000;
  }
}
