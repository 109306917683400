@import "../../../App";

.pdf-skeleton__container {
  background-color: $annotator-background;
  width: calc(100vw - $transition-view-width - $side-bar-width);
  height: 100%;
  display: flex;
  justify-content: center;

  .pdf-skeleton__page {
    width: 894px;
    height: 1263px;
    background-color: $white-color;
    margin-top: 50px;
    padding: 50px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}
