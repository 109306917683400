@import "../../../../../app/App";

.stp-entities__container {
  height: 100%;

  .stp-entities-table {
    width: 100%;

    ::-webkit-scrollbar {
      height: 8px !important;
    }

    .ant-table-row {
      height: 50px;

      .delete-container {
        display: flex;
        justify-content: center;
      }

      .entity-select .ant-select-selector {
        color: $black-color !important;
      }
    }

    .footer__add-button {
      width: 100%;
      overflow-x: auto;
    }
  }

  .selected-rows-actions {
    display: flex;
    justify-content: space-between;
  }
}
