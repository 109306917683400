@import "../HeaderWithTitle/HeaderWithTitle";

.header-with-title__container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .flex__wrapper {
    display: flex;
    align-items: center;
  }

  .user-management-invites__button {
    margin-right: 10px;
  }
}