@import "../../../app/App";

.dropdown-actions__divider {
  margin: $margin-xs;
}

.dropdown-actions__buttons {
  display: flex;
  justify-content: flex-end;
  padding: 0 $padding-xss $padding-xss;

  .ant-btn {
    height: 30px;
  }
}