.maintenance__container {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;

  .maintenance__logo {
    width: 40%;
    height: auto;
  }

  .maintenance__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .maintenance__text {
      font-size: 20px;

      mark {
        background-color: #38B2AC;
      }
    }
  }
}
