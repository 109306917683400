.shortcut-help__container {
  .shortcut-help__section {
    padding: 10px 0;

    .shortcut-help__sub-title {
      display: flex;

      .extra-info__icon {
        margin-left: 5px;
      }
    }
  }

  kbd {
    background-color: #eee;
    border-radius: 3px;
    border: 1px solid #b4b4b4;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
    color: #333;
    display: inline-block;
    font-size: 0.85em;
    font-weight: 700;
    line-height: 1;
    padding: 2px 4px;
    white-space: nowrap;
  }
}