@import "../../app/App";

.header-accent__container {
  display: flex;
  flex-direction: column;

  .header-accent__text {
    font-size: 24px;
    line-height: 24px;
    padding-bottom: 5px;
    font-weight: 500;
  }

  .header-accent__accent {
    width: 50px;
    border: 2px solid $primary-color;
    border-radius: 25px;
    margin: 0;
  }
}
