@import "../../../../app/App";

.annotation-layer__page-container {
  position: absolute;
  top: 0;
  left: 0;

  .annotation-layer__item-container {
    .annotation-layer__item-container-inner {
      .annotation-layer__item {
        cursor: pointer;
        position: absolute;
        z-index: 16;
        border-radius: 1px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.pulsate-fwd {
          animation: pulsate-fwd 0.5s ease-in-out 3 both;
        }
      }

      .annotation-item-menu__container {
        position: absolute;
        transform: rotate(-45deg);
        opacity: 0;
        display: flex;
        flex-direction: column;
        gap: 6px;
        z-index: 20;
        transition: opacity 0.5s ease-in-out;

        &:hover {
          opacity: 1;
        }

        .annotation-item-menu__item {
          display: flex;
          background-color: #2d313b;
          color: $white-color;
          padding: 5px;
          border-radius: 50%;
          cursor: pointer;
          transform: rotate(45deg);
        }
      }

      &:hover {
        .annotation-item-menu__container {
          opacity: 1;
        }
      }
    }


    .annotation-item__connection {
      visibility: hidden;
    }


    &:hover {
      .annotation-item__connection {
        visibility: visible;
      }
    }
  }
}

@keyframes pulsate-fwd {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}