@import "../../../app/App";

.transition-view-header {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  align-items: center;
  padding: 0 $padding-md;

  &.reverse {
    padding: 0 $padding-md;
    flex-direction: row-reverse;

    .route-icon-container {
      transform: rotate(180deg);
    }
  }

  .route-icon-container {
    cursor: pointer;
    order: 3;
    background-color: $dark-grey-color;
    transition: background-color 0.3s ease-in;
    border-radius: 5px;
    padding: $padding-xs $padding-sm;

    svg {
      margin-top: 2px;
      path {
        transition: fill 0.3s ease-in;
        fill: $white-color;
      }
    }

    &:hover {
      transition: background-color 0.3s ease-in;
      background-color: $extra-dark-grey-color;
    }
  }

  .actions {
    cursor: pointer;
    background-color: $dark-grey-color;
    transition: background-color 0.3s ease-in;
    border-radius: 5px;
    padding: $padding-xs $padding-xss;
    font-size: 22px;
    color: $white-color;

    &:hover {
      transition: background-color 0.3s ease-in;
      background-color: $extra-dark-grey-color;
    }
  }
}
