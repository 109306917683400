@import "../../../app/App";

$disabled-no-parent-value-color: #6c6c6c;

.categorization-item__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1px 2px;
  height: 29px;
  justify-content: center;

  .ant-select {
    height: 100%;
    background-color: $white-color;
    border-radius: 6px;
  }

  .categorization-item__select-disabled .ant-select-selection-item {
    color: black;
    opacity: 0.5;
  }

  .categorization-item__select-is-incomplete {
    border: 3px solid $error-color;
    border-radius: 5px;
  }

  .categorization-item__select-no-parent-value {
    color: white !important;

    .ant-select-selector {
      background-color: $disabled-no-parent-value-color !important;
      opacity: 0.8;
      border: none;
    }
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.select__empty-state {
  display: flex;
  justify-content: center;
  color: #888888;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}