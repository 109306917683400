@import "../../app/App";

.circle-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: $status-error-color;
}

.to-do {
  background-color: $status-to-do-color !important;
}

.in-progress {
  background-color: $status-in-progress-color !important;
}

.on-hold {
  background-color: $status-on-hold-color !important;
}

.done {
  background-color: $status-done-color !important;
}

.rejected {
  background-color: $status-rejected-color !important;
}

.exported {
  background-color: $status-exported-color !important;
}

.export-failed {
  background-color: $status-export-failed-color !important;
}

.error {
  background-color: $status-error-color !important;
}

.removed {
  background-color: $status-removed-color !important;
}

.processing {
  background-color: $status-processing-color !important;
}