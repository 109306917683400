@import "../../../app/App";

.actions-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;

  .default-icon {
    cursor: pointer;
    color: $table-line-action-color;
    font-size: 15px;

    &:active {
      transform: scale(0.95);
    }

    &:hover {
      transition: color 0.3s ease-in;
      color: $white-color;
    }
  }

  .delete-line-icon {
    cursor: pointer;
    color: $table-line-action-color;
    font-size: 15px;

    &:active {
      transform: scale(0.95);
    }

    &:hover {
      transition: color 0.3s ease-in;
      color: $btn-danger-bg;
    }
  }

  .group-block-button-add {
    &.btn-focused {
      outline: 5px solid $primary-color;
      border-radius: 5px;
    }
  }
}