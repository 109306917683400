.reject-modal__content-container {
  padding: 20px 20px 0 20px;

  h3 {
    font-weight: 700;
    margin: 0;
    padding-bottom: 25px;
  }

  .reject-modal__sender {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    padding: 5px 0;

    h4 {
      margin-bottom: 0;
      margin-right: 5px;
      font-weight: 700;
    }

    h5 {
      margin-bottom: 0;
    }
  }

  .reject-modal__content-editor {
    .ql-container {
      height: 200px;
    }
  }
}

.ant-modal-footer {
  padding-bottom: 20px !important;
}
