$primary-color: #38b2ac;
$secondary-color: #2d3748;
$black-color: #000;
$extra-dark-grey-color: #22262f;
$dark-grey-color: #343a40;
$medium-dark-grey-color: #444851;
$normal-grey-color: #6c757d;
$light-grey-color: #f7f9fc;
$overview-background-grey-color: #f8f8f8;
$white-color: #fff;
$annotator-background: #2C3038;

$error-color: #ff4d4f;
$success-color: #52c41a;
$warning-color: #faad14;
$info-color: #1677ff;

$error-dark-color: #ae3f3f;
$info-dark-color: #6fa0f3;
$warning-dark-color: #ca8d1a;
$annotated-dark-color: #436343;

$status-to-do-color: #15C2C2;
$status-in-progress-color: #41A9FF;
$status-on-hold-color: #FAAE13;
$status-done-color: #52C41A;
$status-rejected-color: #EA3096;
$status-exported-color: #9254DE;
$status-export-failed-color: #ad2102;
$status-error-color: $error-color;
$status-removed-color: #f0673a;
$status-processing-color: #fff103;

$btn-danger-bg: $error-color;

$height-lg: 40px;

$margin-lg: 24px; // containers
$margin-md: 16px; // small containers and buttons
$margin-sm: 12px; // Form controls and items
$margin-xs: 8px; // small items
$margin-xss: 4px;

$padding-lg: 24px; // containers
$padding-md: 16px; // small containers and buttons
$padding-sm: 12px; // Form controls and items
$padding-xs: 8px; // small items
$padding-xss: 4px; // more small

$padding-xl: 30px;
$margin-xl: 36px;
$padding-xsss: 3px;
$padding-xxl: 44px;
$padding-xxxl: 52px;

$font-family: "Inter", sans-serif;
$font-size-xxs: 9px;
$font-size-xs: 11px;
$font-size-sm: 13px;
$font-size-base: 14px;
$font-size-md: 18px;
$font-size-large: 24px;
$font-size-xl: 36px;
$line-height-base: 17px;
$line-height-lg: 36px;

$font-weight-light: 200;
$font-weight-regular: 400;
$font-weight-semi-bold: 500;
$font-weight-bold: 600;
$font-weight-extra-bold: 700;
$font-weight-black: 800;

$heading-color: #000;
$text-color: $dark-grey-color;
$text-color-secondary: $normal-grey-color;

$border-radius-base: 6px;

$layout-header-background: $white-color;
$layout-header-height: 64px;
$layout-header-padding: 0 $padding-xxl;
$layout-sider-background: $primary-color;
$layout-sider-background-light: $primary-color;
$menu-bg: $primary-color;
$menu-item-font-size: 17px;
$menu-collapsed-width: 0px;

$btn-height-base: 38px;
$btn-primary-color: $dark-grey-color;
$btn-default-border: $secondary-color;
$btn-default-color: $secondary-color;
$btn-disable-bg: #38b2ac 35%;
$btn-font-weight: $font-weight-bold;
$btn-border-width: 1px;
$btn-border-radius-base: 10px;
$btn-padding-horizontal-base: 15px;

$radio-button-color: $btn-primary-color;
$radio-button-bg: transparent;
$radio-dot-color: $btn-primary-color;

$checkbox-check-bg: transform;
$checkbox-check: transparent;
$checkbox-color: $btn-primary-color;

$box-shadow-base: 0px 1px 0px 0px #f1f1f1;

$column-header-background: #eceaea;
$column-header-collapse-icon-background: #dcdcdc;

$drawer-bg: transparent;
$drawer-body-padding: 0 0;

$transition-view-width: 480px;
$transition-view-header-height: 65px;
$transition-view-footer-height: 130px;

$group-block-grey-color: #363a43;
$group-block-list-item-text-color: #888888;
$table-line-action-color: #bab7b7;
$list-item-padding: $padding-xs $padding-lg;
$list-item-meta-description-font-size: $font-size-sm;

$avatar-border-radius: 10px;
$avatar-size-base: 40px;

$modal-body-padding: $padding-lg;
$modal-header-close-size: 33px;

$tooltip-bg: $extra-dark-grey-color;

$box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.20);


$padding-page-base: 25px;
$padding-total: $padding-page-base*2;

$side-bar-width: 85px;
$table-header: 60px;
$controls-header: 60px;

h1, h2, h3, h4, h5 {
  margin: 0;
}

.app__container {
  font-family: 'Inter', sans-serif;
  height: 100vh;

  .ant-layout {
    height: 100vh;
  }

  .page__wrapper {
    background-color: $secondary-color;
    padding: $padding-page-base;
  }
}

.logo {
  padding: 15px 24px;
  height: $layout-header-height;
}

div {
  div.ant-tooltip {
    div.ant-tooltip-content {
      div.ant-tooltip-inner {
        box-shadow: none;
      }
    }
  }
}

*::-webkit-scrollbar {
  width: 7px;
  border-radius: 3.5px;
}

*::-webkit-scrollbar-track {
  border-radius: 3.5px;
}

*::-webkit-scrollbar-thumb {
  background: $normal-grey-color;
  border-radius: 3.5px;
}

*::-webkit-scrollbar-thumb:hover {
  background: $normal-grey-color;
}

::selection {
  background: $primary-color;
  color: #000;
}

.hidden {
  display: none !important;
}