@import "../../../app/App";

.personal-page__container {
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  color: $black-color;
  height: 100%;
  border-top-left-radius: $border-radius-base;
  padding: 50px 100px;
  background-color: $overview-background-grey-color;
  z-index: 1;

  h5, label {
    color: $black-color;
  }

  .personal-page__spacer {
    padding: 25px;
  }

  .personal-page__placeholder {
    width: 175px;
  }

  h2 {
    margin-bottom: 20px;
    padding-bottom: 30px;
    font-size: 38px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      line-height: 38px;
    }

    i {
      cursor: pointer;
    }
  }

  .personal-page__save-button {
    width: 400px;
  }

  .personal-page__section {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 100px;
    grid-row-gap: 75px;
    padding-bottom: 75px;

    .personal-page__section-wrapper {
      display: flex;
      gap: 100px;
    }
  }


  .personal-page__user-info-container {
    display: flex;
    gap: 100px;

    .personal-page__avatar-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .personal-page__avatar {
        background-color: $primary-color;
        margin-bottom: 20px;

        .ant-avatar-string {
          font-size: 56px;
        }
      }

      .personal-page__avatar-controls {
        display: flex;
        gap: 10px;

        .personal-page__custom-input {
          width: fit-content;
          color: $primary-color;
          background-color: #f1f1f1;
          box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.20);
          backdrop-filter: blur(5px);
          border: 1px solid $primary-color;
          padding: 10px 16px;
          cursor: pointer;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 25px;

          input[type="file"] {
            display: none;
          }
        }


        button {
          color: $primary-color;
          background: #f1f1f1;
          box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.20);
          backdrop-filter: blur(5px);
          border-radius: 25px;
          border: 1px solid $primary-color;
        }
      }
    }

    .personal-page__user-info {
      h5 {
        font-size: 14px;
        font-weight: 700;
      }

      .personal-page__label {
        padding-bottom: 15px;
        display: block;
      }
    }
  }

  .personal-page__settings-items {
    width: 400px;

    .account-popover__item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      div {
        display: flex;
        align-items: center;
      }

      &.link {
        cursor: pointer;

        .account-popover__item-label {
          cursor: inherit;
        }
      }

      .account-popover__item-icon {
        margin-right: 10px;
        font-size: 20px;
        color: $black-color;
      }

      .account-popover__item-label {
        margin: 0 8px 0 3px;
        font-size: 16px;
        line-height: 22px;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
