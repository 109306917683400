.stp-entity-footer-add__container {
  display: flex;

  .stp-entity-footer-add__content {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .stp-entity-footer-add__spacer {
    min-width: 22px;
    width: 3%;
  }

  .full-width {
    width: 100%;
  }

  .column-padding {
    padding-left: 8px;
    padding-right: 8px;
  }
}