@import "../../../app/App";

.login-page__container {
  text-align: center;

  h3 {
    font-size: $font-size-large;
    margin-bottom: $margin-xs;
    font-weight: $font-weight-semi-bold;
  }

  h2 {
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    margin-bottom: $margin-md;
  }

  .sign-in-button {
    margin-top: 20px;
    width: 100%;
    color: $secondary-color;
    font-size: 15px;

    .sign-in-button__icon {
      font-size: 17px;
    }

    &:hover {
      color: $white-color;
      background-color: $secondary-color !important;
    }
  }

  .login-form {
    display: flex;
    flex-direction: column;
    .sign-in-container {


      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
