.add-member-modal__container {
  .ant-modal-title {
    font-size: 24px;
  }

  .add-member-modal__body {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}