@import "../../../../app/App";

$header-font-size: 30px;

.header-with-title__container {
  display: flex;
  align-items: center;
  background-color: $secondary-color !important;
  padding: 15px 20px !important;
  height: $layout-header-height;

  .header-with-title__divider {
    height: 20px;
    border: 1px solid white;
    margin: 0 2px;
  }

  .header-with-title__back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    padding: $padding-xss $padding-sm;
    height: 40px;
    font-size: 27px !important;
    transition: background-color 0.3s ease-in;

    &:hover {
      transition: background-color 0.3s ease-in;
      background-color: $extra-dark-grey-color;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .header-with-title__title {
    color: $white-color;
    font-size: $header-font-size;
    font-weight: $font-weight-regular;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}
