.filter-header__container {
  display: flex;
  margin-bottom: 20px;
  align-items: flex-end;
  padding: 0 20px;

  .filter-item__container {
    display: flex;
    flex-direction: column;

    .filter-item {
      margin-right: 20px;
      margin-top: 5px;

      .filter-item__select {
        width: 200px;
      }
    }

    .filter-item__label {
      margin: 0 0 0 5px;
    }

    .range-picker {
      width: 280px;
    }

    .filter-item-periods__container {
      display: flex;
      align-items: flex-end;
      width: 280px;

      .filter-item-periods__label {
        margin: 0 0 0 5px;
        justify-items: flex-end;
      }

      .filter-item-periods {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;

        .filter-item-periods__button {
          height: 23px;
          width: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}