@import "../../../../app/App";

.not-rendered-overlay__container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1px);

  .not-rendered-overlay__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .not-rendered-overlay__info-icon {
      font-size: 40px;
      color: $primary-color;
      background-color: $secondary-color;
      border-radius: 50%;
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-btn {
      width: 300px;
      color: $white-color;
      border: 2px solid $secondary-color;
    }
  }
}