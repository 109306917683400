@import "../../../app/App";

.transition-view-footer__container {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  bottom: 0;
  width: $transition-view-width;
  background-color: $dark-grey-color !important;
  z-index: 100;

  .action-button__container {
    display: block;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid #2C3038;
    background: rgba(44, 48, 56, 0.80);
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(5px);

    .ant-btn {
      border: none;
      border-radius: 0;
      align-items: center !important;
      text-align: center;

      &.ant-btn-background-ghost {
        background: linear-gradient(180deg, rgba(44, 48, 56, 0.20) 0%, rgba(45, 55, 72, 0.20) 100%);
        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.20);
        backdrop-filter: blur(2px);
      }

      .person-take-over-icon {
        font-size: 17px;
        height: 42px;
      }
    }
  }

  .footer-status {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .buttons__container {
      display: flex;
      gap: 5px;

      .reply-count {
        sup {
          box-shadow: 0 0 0 1px #363a43;
        }
      }

      .footer-reply-button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        background: #22262F;
        border-radius: 50%;

        i {
          color: white;
        }
      }
    }

    .timestamp {
      margin-bottom: 0;
      margin-top: $margin-xs;
      font-size: $font-size-xs;
      color: $group-block-list-item-text-color;
    }
  }
}

.reply-body {
  max-width: 400px;

  .reply-body__header {
    padding-top: 10px;
    display: flex;
    align-items: baseline;

    h4 {
      font-weight: 700;
      margin-right: 5px
    }
  }

  .ant-popover-content {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}