.table-data-overview__container {
  width: 100%;
  height: 200px;
  position: absolute;
  background-color: #323639;
  color: #fff;
  bottom: 0;
  z-index: 16;
  overflow-y: auto;

  h2 {
    padding: 0 50px;
  }

  .remove-line-icon {
    cursor: pointer;
  }

  table {
    width: 100%;
    padding: 10px;
  }

  td {
    text-align: center;
    vertical-align: middle;
  }

  .table-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #323639;
    position: sticky;
    bottom: 0;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 30px;
      cursor: pointer;
      border-radius: 50px;
      margin: 10px;
      border: 2px solid #767676;
      background: #22262f;
      color: #fff;


      &:hover {
        border: 2px solid #5c5c5c;
      }

      &:active {
        background: #0f1014;
      }
    }
  }
}