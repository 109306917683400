.value-input__container {
  width: 400px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;

  button {
    height: 20px;
  }
}
