@import "../../app/App";

$document-type-width: 250px;

.select-document-type__container {
  order: 1;
  text-align: center;
  width: $document-type-width;
  margin: 0 auto;

  .ant-select-selector {
    background: #ffffff !important;
  }

  .select-document-type__label {
    display: block;
    color: $white-color;
    margin-bottom: 5px;
  }

  .select-document-type__selector {
    cursor: pointer;
    width: 100%;
  }
}
