@import "../../../app/App";

.organizations-settings__container {
  display: flex;
  color: $black-color;
  height: 100%;
  overflow-y: hidden;
  border-top-left-radius: $border-radius-base;
  background-color: $overview-background-grey-color;
  z-index: 1;

  .organizations-settings__side-menu {
    width: 240px;
    height: calc(100vh - $layout-header-height);

    .organizations-settings-menu__side-menu {
      width: 240px;
      height: 100%;
      padding: 20px 5px;
    }

    li {
      font-size: 13px;
      padding: 0 10px !important;
    }

    .ant-menu {
      padding: 0 !important;
    }

    .ant-menu-submenu {
      padding: 0 4px !important;
      margin: 0 !important;

      .ant-menu-sub {
        margin-left: 10px;
        background-color: $white-color;
      }

      .ant-menu-submenu-title {
        width: 230px;
        padding: 0 10px 0 10px !important;
        margin: 0 !important;
      }

      .ant-menu-submenu-arrow {
        margin-right: -10px;
      }
    }
  }

  .organizations-settings__content {
    width: 100%;
    height: 100%;

    overflow-y: auto;

    &.padding {
      padding: 20px;
    }

    .organization-settings__section-items {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .organization-settings__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }

      h5 {
        margin-bottom: 5px;
      }
    }
  }


  .configmap-table__action-item {
    color: $primary-color;
    cursor: pointer;
  }

  .header-filter__container {
    display: flex;
    justify-content: space-between;

    .overview-header__icon {
      color: black;
      cursor: pointer;
    }
  }

  .ant-table-thead .ant-table-cell, thead.ant-table-thead {
    background-color: $dark-grey-color !important;
    color: $white-color !important;
    border-radius: 0;
    height: $table-header;

    i {
      color: $white-color !important;
    }
  }

  .group__expand {
    cursor: pointer;
  }

  .delete-button {
    padding: 5px 0;

    &:hover {
      color: $error-color;
      cursor: pointer;
    }
  }

  .ant-tabs-tab {
    .tab-title {
      font-size: 16px;
    }
  }
}