@import "../../../../app/App";

.pie-graph__container {
  position: relative;

  .graph-loader {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
  }

  .g2-tooltip {
    .tooltip__items {
      margin: 12px 0;
      list-style-type: none;
      padding: 0;

      .tooltip__title {
        display: flex;
        justify-content: space-between;
      }

      .tooltip__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style-type: none;
        padding: 0;
        margin: 12px 0;

        .tooltip__item-source {
          max-width: 93px;
        }

        .tooltip__item-color {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 5px;
        }

        .tooltip__item-value {
          margin-left: 20px;
        }
      }
    }
  }
}