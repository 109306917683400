@import "../../../app/App";

.group-blocks__padding-container {
  padding: $padding-sm $padding-lg;
}

.group-blocks {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: inset 0 15px 8px -15px $dark-grey-color,
  inset 0 -15px 8px -15px $dark-grey-color;
  background-color: $group-block-grey-color;
  scrollbar-track-color: $dark-grey-color;

  .delete-annotations-container {
    display: flex;
    justify-content: flex-end;

    .delete-annotations-button {
      margin-left: $margin-md;
      margin-right: $margin-md;
      padding-top: $padding-xs;
      padding-bottom: $padding-xs;
    }
  }

  .entity-group-block-container {
    margin-top: $margin-sm;
    padding: 3px 0;

    .group-block-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-container {
        cursor: default;
        display: inline-flex;
        align-items: center;

        .group-block-name {
          margin: 0;
          font-size: $font-size-md;
          color: $group-block-list-item-text-color;
          text-transform: uppercase;
          padding-left: 16px;
        }

        .group-block__annotations-hover {
          color: #fff;
          padding: 8px;
          font-size: 16px;
        }
      }

      .right-container {
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;

        .group-block-icon {
          padding: 7px;
          cursor: pointer;
          color: $group-block-list-item-text-color;

          &:active {
            transform: scale(0.95);
          }

          &:hover {
            transition: color 0.3s ease-in;
            color: $white-color;
          }
        }
      }
    }

    .entity-group-block-list {
      .list-item-container {
        display: block;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        padding: 3px 0;

        &.disabled {
          cursor: default;
        }

        .ant-list-item {
          border: none;
          padding: 2px 24px;

          &.is-editing {
            .ant-list-item-meta {
              .ant-list-item-meta-content {
                .ant-list-item-meta-description {
                  margin-top: 0;
                }
              }
            }
          }

          &.to-many-annotations {
            .ant-list-item-meta {
              .ant-list-item-meta-content {
                .ant-list-item-meta-title {
                  margin-top: 0;
                }
              }
            }
          }

          .ant-list-item-meta {
            align-items: center;
            .ant-list-item-meta-avatar {
              z-index: 9;
            }

            .ant-list-item-meta-content {
              .ant-list-item-meta-title {
                font-size: $font-size-base;
                color: $group-block-list-item-text-color;

                svg {
                  margin-left: $margin-xs;

                  path {
                    fill: $btn-danger-bg;
                  }
                }
              }

              .ant-list-item-meta-description {
                font-size: $font-size-sm;
                color: $white-color;
                margin-top: $margin-xs;
              }
            }
          }

          div.ant-space {
            .entity-accuracy-score {
              color: $normal-grey-color;
              font-size: $font-size-xs;
              font-style: italic;

              &.hidden {
                display: none;
              }

              .attention-required__icon {
                color: $warning-color;
                margin-right: 5px;
              }

              .entity-accuracy__warning {
                color: $warning-color;
              }
            }

            div.hover-icon {
              margin-top: $padding-xss;
              width: 40px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .edit {
                &:hover {
                  opacity: 1;
                }
              }

              .remove {
                &:hover {
                  color: $error-color;
                  opacity: 1;
                }
              }

              i {
                z-index: 3;
                width: 14px;
                height: 14px;
                font-size: 14px;

                transition: opacity 0.3s ease-in;
                color: rgba($group-block-list-item-text-color, 0%);
                opacity: 0;
              }
            }

            div.loader {
              width: 40px;
              height: 20px;
              margin-top: $padding-xss;
              display: flex;
              justify-content: flex-end;
            }
          }
        }

        &:hover {
          background-color: $dark-grey-color;

          .ant-list-item {
            .ant-space {
              .ant-space-item {
                .hover-icon {
                  i {
                    transition: opacity 0.3s ease-out;
                    color: $white-color;
                    opacity: 0.6;
                  }

                  svg.alert-circle {
                    transition: opacity 0.3s ease-out;
                    color: $btn-danger-bg;
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }

    .group-block__add-button-container {
      display: flex;
      justify-content: flex-end;
      color: #fff;
      align-items: center;

      &:hover {
        -webkit-transition: 200ms linear;
        -ms-transition: 200ms linear;
        transition: 200ms linear;

        .group-block__add-button-icon {
          color: $white-color !important;
        }

        .line {
          background-color: $white-color;
        }
      }

      .separator {
        display: flex;
        align-items: center;
      }
      .line {
        -webkit-transition: 200ms linear;
        -ms-transition: 200ms linear;
        transition: 200ms linear;
        height: 1px;
        flex: 1;
        background-color: $normal-grey-color;
        margin-left: 15px;
        margin-right: 15px;
      }
      .group-block__add-button {
        cursor: pointer;

        &.btn-focused {
          outline: 5px solid $primary-color;
          border-radius: 5px;
        }

        .group-block__add-button-icon {
          -webkit-transition: 200ms linear;
          -ms-transition: 200ms linear;
          transition: 200ms linear;
          color: $normal-grey-color;
          font-size: 20px;
        }

        &:active {
          transform: scale(0.95);
        }
      }
    }
  }
}
