@import "../../../app/App";

$menu-bg-color: $secondary-color;
$menu-highlight-color: $primary-color;
$menu-accent-color: $white-color;
$menu-item-active-bg: #4f596a;

.side-menu__container {
  background-color: $menu-bg-color !important;
  height: 100%;
  display: flex;
  flex-direction: column;

  .blink {
    animation-duration: 800ms;
    animation-name: blink;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  .side-menu__logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: $layout-header-height;

    .side-menu__logo {
      height: 25px;
      margin: 16px 0 14px 0;
    }
  }

  .side-menu__items {
    width: 100%;
    border: none;
    background: $menu-bg-color !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    .side-menu__item {
      width: 70px;
      height: 65px;
      color: $menu-accent-color;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      border-radius: 10px;
      padding: 10px;
      margin: 0 0 5px;

      .ant-menu-title-content {
        margin: 0;
        padding: 0;
        line-height: 18px;
      }

      .side-menu__item-icon {
        color: white;
        font-size: 17px;
      }

      &.active {
        background-color: $menu-item-active-bg;
        color: $menu-highlight-color;

        .side-menu__item-icon {
          color: $menu-highlight-color;
        }
      }

      &.hidden {
        display: none;
      }

      &:hover {
        background-color: $menu-highlight-color !important;

        .side-menu__item-icon {
          color: $white-color;
        }
      }

      .side-menu__item-link {
        padding: 0;
        color: inherit;
        display: inline-block;
        line-height: 15px;
        white-space: pre-wrap;
        font-size: $font-size-sm;
        text-align: center;

        &:hover {
          color: $menu-accent-color;
        }
      }
    }

    .side-menu__item-account {
      color: $menu-accent-color;
      height: auto;
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 70px;
    }
  }

  .side-menu__profile-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    margin: 0 0 10px;

    .side-menu__profile-account {
      margin-top: 10px;

      .header__user-name {
        padding-left: $padding-xs;
        font-size: $font-size-base;
        color: $dark-grey-color;
      }
    }
  }
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
