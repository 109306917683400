@import "../../app/App";

.email-templates__container {
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  color: $white-color;
  height: 100%;

  .email_templates__placeholder {
    display: flex;
    background-color: $overview-background-grey-color;
    flex: 1 1 auto;
  }

  .ant-table-thead .ant-table-cell, thead.ant-table-thead {
    background-color: $dark-grey-color !important;
    color: $white-color !important;
    border-radius: 0;
    height: $table-header;
  }

  .ant-table-cell::before {
    background-color: $normal-grey-color !important;
  }

  .ant-table-cell-scrollbar::before {
    background-color: $dark-grey-color !important;
  }

  .email-templates__button {
    position: absolute;
    display: inline-block;
    right: 20px;
    top: 5px;
    float: right;
    width: auto;
    z-index: 100;
  }

  .ant-btn {
    border-color: $white-color !important;
    background-color: $secondary-color !important;

    &:hover {
      color: $primary-color !important;
      border-color: $primary-color !important;
    }

    &:disabled {
      border-color: $normal-grey-color !important;
      color: $normal-grey-color !important;
    }
  }
  
  .email-template__table {
    tr {
      cursor: pointer;
    }
  }

  h2 {
    padding-bottom: 30px;
    font-size: 38px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  i.bi-trash {
    &:hover {
      color: $error-color;
    }
  }

  i.bi-star {
    &:hover {
      color: orange;
    }
  }
}

