@import "../../../app/App";

div.ant-dropdown {
  ul.ant-dropdown-menu {
    min-width: 220px;
    border: 0.5px $primary-color solid;
    margin-left: -$margin-lg;
    z-index: 2;

    li.ant-dropdown-menu-item {
      color: $text-color;
      margin: $margin-xss 0;
      padding: $padding-xs 0 $padding-xs $padding-md;

      &.ant-dropdown-menu-item-selected {
        color: $white-color;
        background-color: $secondary-color;
      }
    }
  }
}

.overview-header-container {
  display: flex;

  .whitespace-left {
    min-width: 60px;
    min-height: 1px;
  }

  .whitespace-right {
    min-width: 30px;
    min-height: 1px;
  }

  .overview-header {
    width: 100%;

    .overview-header__icon {
      font-size: 20px !important;
      color: white;
      cursor: pointer;
    }

    .column-header-container {
      .column-header-content {
        display: inline-block;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        &.no-filter-sort {
          cursor: default;

          &:hover {
            background-color: transparent;
          }
        }

        &.can-sort,
        &.can-filter {
          cursor: pointer;

          &:hover {
            background-color: $column-header-background;
            transition: background-color 0.27s ease-in-out;
          }

          &.filter-active,
          &.sort-active {
            div.content-align-container {
              p.header-text {
                color: $black-color;
                font-weight: $font-weight-semi-bold;
              }

              svg {
                path {
                  fill: $black-color;
                }
              }
            }
          }
        }
      }

      div.content-align-container {
        padding: $padding-md 8px;
        min-height: 25px + 2 * $padding-md;
        display: flex;
        align-items: center;

        p.header-text,
        svg {
          display: inline-block;
        }

        p.header-text {
          font-size: $font-size-base;
          margin-bottom: 0;
          user-select: none;
          margin-left: $margin-xs;
        }


        svg.column-icon {
          margin-right: $margin-xs;
        }

        i {
          font-size: 16px;
          color: #8e9ba6;
        }
      }
    }
  }
}

.sort__container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  .sort__icon {
    &.active {
      color: #38b2ac !important;
    }
  }

  i {
    font-size: 10px !important;
    display: inline-flex;

    &:not(:last-child) {
      margin-bottom: -2px;
    }
  }
}


.filter-popover {
  .ant-popover-content {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

    .ant-popover-inner {
      padding: 10px !important;
    }
  }

  .filter-popover__container {
    .filter-popover__item {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding-bottom: 20px;
      min-width: 350px;
      max-width: 350px;

      &.hidden {
        display: none;
      }

      .filter-popover__label {
        i {
          margin-left: 5px;
        }
      }

      .filter-popover__select {
        width: 100%;
      }

      .filter-popover__input {
        height: 32px;
      }

      .filter-popover__range-picker {
        height: 32px;
      }
    }

    .blank-entries-checkbox-container {
      flex-direction: row;
      margin-bottom: $margin-xss;
    }

    .extra-select-filter-container {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 350px;
      margin-top: 5px;
    }

    .ant-picker-footer-extra {
      margin-top: 5px;
      padding: $padding-xss;
    }
  }

  .filter-popover__checkbox-container {
    margin-top: 10px;
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
  }
}

.avatar__placeholder {
  width: 42px;
  height: 42px;
}
