@import "../../../app/App";

$header-font-size: 30px;

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $secondary-color !important;
  padding: 15px 20px !important;
  height: $layout-header-height;

  .header-title {
    color: $white-color;
    font-size: $header-font-size;
    font-weight: $font-weight-regular;
  }

  .header__menu-icon {
    color: $dark-grey-color;
    cursor: pointer;
    font-size: 20px;
    padding: calc($padding-sm + 2px);
  }

  .header__menu-placeholder {
    visibility: hidden;
  }

  .header-status__container {
    justify-content: space-between;
  }

  .header__status-button {
    border-radius: 0;
    background-color: $secondary-color;
    border-bottom-color: $secondary-color;
    color: $white-color;
    border-top: none;
    border-left: none;
    border-right: none;
    margin-right: 10px;

    &.active {
      color: $primary-color;
      border-bottom: 2px solid $primary-color;
    }

    &:hover, &:focus {
      background-color: $secondary-color !important;
    }

    &::after {
      all: unset;
    }
  }
}
