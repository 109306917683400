@import "../../../app/App";

.metric-graphs__container {
  padding: 0 20px;
  height: calc(100% - 54px);
  overflow-y: auto;

  .metric-graphs-row {
    display: flex;
    width: 100%;
    max-height: 310px;
    margin-bottom: 50px;

    .line-graph {
      width: calc(100% - 20px);
      margin-right: 20px;
    }

    .pie-graph {
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
  }
}
