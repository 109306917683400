@import "../../../app/App";

.metric-graphs__container {
  padding: 0 20px;
  height: calc(100% - 54px);
  overflow-y: auto;

  .interactive-metric-graphs-row {
    display: flex;
    width: 100%;
    max-height: 310px;

    .line-graph {
      width: calc(100% - 315px);
      margin-right: 15px;
    }

    .pie-graph {
      overflow-y: auto;
    }
  }
}