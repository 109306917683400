.skeleton-source__container {
  padding: 10px 20px;
  margin-bottom: 20px;
  width: 100%;

  .skeleton-source__title {
    width: 270px;
    margin-bottom: 30px;
  }

  .skeleton-source__sections {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;

    .skeleton-source__section {
      width: 25%;
      margin-bottom: 10px;
      height: 40px;

      .skeleton-source__section-title {
        width: 20%;
      }

      .skeleton-source__section-content {
        width: 30%;
      }

      .skeleton-source__section-volume {
        width: 80px;
      }

      .skeleton-source__section-source {
        width: 250px;
      }
    }
  }
}