.document-preview__container {
  display: flex;
  overflow: auto;
  justify-content: center;
  height: 350px;
  width: 400px;
  border-radius: 5px;

  .error-alert {
    width: 100%;
    height: 50px;
  }
}