@import "../../../app/App";

.avatar__container {
  position: relative;
  display: inline-block;
  padding: 5px;

  .avatar__remove-icon {
    cursor: pointer;
    visibility: hidden;
    position: absolute;
    top: -1px;
    right: -2px;
    color: $light-grey-color;
    background-color: #343a40;
    border-radius: 50%;
    font-weight: 700;
    border: 1px solid $white-color;
    z-index: 3;
    width: 20px;
    height: 20px;

    i {
      position: relative;
      top: -2px;
      left: 2px;
      font-size: 14px;
    }

    &:hover {
      color: $white-color;
      background-color: $primary-color;
    }
  }

  &:hover {
    transform: scale(1.1);

    .avatar__remove-icon {
      visibility: visible;
    }
  }
}
