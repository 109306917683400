@import "../../../../app/App";

.order-columns-popover {
  .ant-popover-content {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

    .ant-popover-inner {
      padding: 10px !important;
    }
  }

  .order-columns-popover__container {
    .order-columns-popover__item {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding-bottom: 10px;
      min-width: 250px;

      .card__container {
        list-style: none;
        padding-left: 0;
        width: 100%;

        .card {
          display: flex;
          justify-content: space-between;
          border: 1px solid #A1A7AC;
          background-color: $light-grey-color;
          border-radius: $border-radius-base;
          margin-bottom: $margin-sm;
          padding: $padding-xs $padding-xs $padding-xs $padding-sm;
          cursor: pointer;

          &:hover, &:active {
            color: $primary-color;
          }

          .card-children-group__wrapper {
            display: flex;
            .card__icon {
              margin-right: $margin-sm;
            }

            .text_strike-through {
              text-decoration: line-through;
            }
          }

          .card-hide__button {
            width: 20px;
            height: 20px;
            border: 1px solid #a1a7ac;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              background-color: $primary-color;

              .bi {
                color: $white-color;
              }
            }
          }
        }
      }
    }
  }
}

.menu-icon {
  font-size: 17px !important;
  transform: rotate(-90deg);
  cursor: pointer;
}

.help-icon {
  margin-left: 5px;
}