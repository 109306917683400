@import "../../../../app/App";

.graph__container {
  padding: 20px 10px;
  margin-bottom: 15px;
  position: relative;

  .graph-loader {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
  }
}