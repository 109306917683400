.email-template-modal__content-container {
  padding: 20px 20px 0 20px;

  .label {
    margin-bottom: 8px;
    margin-right: 5px;
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
    margin: 0;
    padding-bottom: 25px;
  }

  .email-template-modal__name {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }

  .email-template-modal__content-editor {
    .ql-container {
      height: 200px;
    }

    margin-bottom: 15px;
  }
}
