@import "../../../../../app/App";

$side-drawer-value: #dfdede;
$title-color: #9f9e9e;

.ant-drawer-header {
  color: $white-color;

  .anticon-close {
    color: $white-color;
  }
}

.content-container {
  .ant-timeline-item {
    color: $white-color !important;
  }

  .time-title {
    font-size: $font-size-base;
    text-transform: capitalize;
  }

  .title {
    font-weight: bold;
    display: block;
    text-transform: capitalize;
  }

  .entity-group-block-name {
    color: $title-color;
    font-size: $font-size-sm;
    display: block;
    text-transform: capitalize;
  }

  .value {
    font-style: italic;
    display: inline-block;

    &.error {
      background-color: $error-color;
      color: $white-color;
      padding: 0 5px;
      border-radius: 3px;
    }
  }

  .add-icon {
    background-color: $dark-grey-color;
    color: #48e41d;
  }

  .modified-icon {
    background-color: $dark-grey-color;
    color: #4384f8;
  }

  .delete-icon {
    background-color: $dark-grey-color;
    color: $error-color;
  }
}

.ant-drawer-content-wrapper {
  top: $layout-header-height !important;
}

.ant-timeline-item-head {
  padding-block: 0 !important;
}

.ant-timeline-item-label {
  width: 30% !important;
}

.ant-timeline-item-head {
  inset-inline-start: 33% !important;
}

.ant-timeline-item-content {
  width: 68% !important;
  inset-inline-start: 37% !important;
  margin-inline-start: 0 !important;
}