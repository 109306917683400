.permission-tag__container {
  display: flex;
  overflow-x: hidden;
}

.permission-tags-popover__container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 300px;

  .permission-tag:not(:last-child) {
    margin-bottom: 10px;
  }
}
