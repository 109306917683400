.stp-suppliers__container {
  height: 100%;

  .stp-suppliers-table {
    width: 100%;

    .ant-table-row {
      height: 50px;
    }
  }

  .selected-rows-actions {
    display: flex;
    justify-content: space-between;
  }
}