.confirm-remove-modal__content-container {
  padding: 20px;
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: 50px auto;

  i {
    font-size: 26px;
  }

  h3 {
    font-weight: 600;
    margin: 0;
  }
}
