@import "../../app/App";

.loader__container {
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .circle-loader {
    border-radius: 50%;
    background: transparent;
    position: relative;
    animation: rotate 1s linear infinite;

    &.large {
      width: 48px;
      height: 48px;
      border: 4px solid $primary-color;
      border-right-color: transparent;
    }

    &.default {
      width: 24px;
      height: 24px;
      border: 2px solid $primary-color;
      border-right-color: transparent;
    }

    &.small {
      width: 17px;
      height: 17px;
      border: 1px solid $primary-color;
      border-right-color: transparent;
    }
  }

  &.full-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(16, 16, 16, 0.6);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}