@import "../../app/App";

.annotator-container {
  position: relative;
  height: inherit;
  max-height: inherit;
  background-color: #525659;
  box-sizing: border-box;

  .active-entity {
    position: absolute;
    padding: 10px 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
    width: auto;
    left: 50%;
    top: calc(100% - 38px);
    transform: translateX(-50%);
    z-index: 11;
    font-weight: bold;
  }

  .annotator-pages-container {
    height: 100%;
    overflow-y: auto;

    .annotator-pages {
      padding-bottom: 50px;
    }
  }
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .error-icon {
    font-size: 50px;
    color: white;
    padding: 10px;
  }

  .error-message {
    color: white;
  }
}

.controls__container {
  position: sticky;
  top: 0;
  height: $controls-header;
  width: 100%;
  z-index: 11;
  margin-bottom: 50px;
  background-color: #323639;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.09), 0 4px 8px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.3), 0 2px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  gap: 10px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .controls__page-container {
    display: flex;
    align-items: center;
    gap: 5px;

    input {
      color: #fff;
      background-color: #191b1c;
      border: none;
      height: 20px;
      width: 20px;
      text-align: center;
    }
  }

  .controls__size-container {
    display: flex;
    align-items: center;
    gap: 15px;

    input {
      color: #fff;
      background-color: #191b1c;
      border: none;
      height: 20px;
      width: 30px;
      text-align: center;
    }
  }

  .controls__divider {
    width: 19px;
    height: 0;
    border: 1px solid #6E798C;
    transform: rotate(-90deg);
  }

  .controls__table-container {
    display: flex;
  }

  .controls__button {
    padding: 9px 10px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .controls__button-icon {
      color: #fff;
    }

    &:hover {
      background-color: #424649;
    }

    &:active {
      background-color: #191b1c;
    }

    &.active {
      background-color: #191b1c;
    }

    &.disabled {
      cursor: not-allowed;

      .controls__button-icon {
        opacity: 0.2;
        color: #fff;
      }
    }
  }

  .controls__previous-button {
    position: absolute;
    left: $margin-xs;

    i {
      font-size: $font-size-large !important;
    }
  }

  .controls__next-button {
    position: absolute;
    right: $margin-xs;

    i {
      font-size: $font-size-large !important;
    }
  }
}

.fab-group-container {
  position: absolute;
  bottom: 10px;
  right: 40px;
  text-align: center;
  padding: inherit;
  width: 50px;
  display: inline-block;

  .fab-group__list {
    z-index: 3;
    list-style: none;
    margin: 0;
    padding: 0;

    .fab-group__list-item {
      .fab-group__button {
        margin: 10px 5px;
        cursor: pointer;
        display: flex;
        background-color: #f7f7f7;
        color: #a7a7a7;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

        .fab-group__button-icon {
          font-weight: bold;
        }

        &:hover {
          text-decoration: none;
          color: #555;
          background: #f5f5f5;
        }

        &:active {
          transform: scale(0.95);
        }
      }
    }
  }
}

.loader-container {
  position: absolute;
  left: calc(50% - 40px);

  .loader__lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
    }

    div:after {
      content: ' ';
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #000;
      margin: -4px 0 0 -4px;
    }

    div:nth-child(1) {
      animation-delay: -0.036s;
    }

    div:nth-child(1):after {
      top: 63px;
      left: 63px;
    }

    div:nth-child(2) {
      animation-delay: -0.072s;
    }

    div:nth-child(2):after {
      top: 68px;
      left: 56px;
    }

    div:nth-child(3) {
      animation-delay: -0.108s;
    }

    div:nth-child(3):after {
      top: 71px;
      left: 48px;
    }

    div:nth-child(4) {
      animation-delay: -0.144s;
    }

    div:nth-child(4):after {
      top: 72px;
      left: 40px;
    }

    div:nth-child(5) {
      animation-delay: -0.18s;
    }

    div:nth-child(5):after {
      top: 71px;
      left: 32px;
    }

    div:nth-child(6) {
      animation-delay: -0.216s;
    }

    div:nth-child(6):after {
      top: 68px;
      left: 24px;
    }

    div:nth-child(7) {
      animation-delay: -0.252s;
    }

    div:nth-child(7):after {
      top: 63px;
      left: 17px;
    }

    div:nth-child(8) {
      animation-delay: -0.288s;
    }

    div:nth-child(8):after {
      top: 56px;
      left: 12px;
    }
  }

  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.mark-container {
  cursor: pointer;
  position: relative;
  padding: 0.75px;
  border-radius: 5px;
  white-space: nowrap;

  &.temp {
    padding: 0;
    border-radius: 0;
  }

  .mark__token {
    &.temp {
      color: transparent;
    }
  }
}

.ocr-info-container {
  position: absolute;
  top: 10px;
  left: 10px;

  .ocr-info__icon {
    width: 20px;
    height: 20px;
    cursor: help;
  }

  .ocr-info__icon-rotate {
    animation: rotate infinite 2s linear;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.token__text-item {
  color: transparent;

  &.annotatable {
    padding: 1px;
    border: 1px solid #bebebe;
    border-radius: 5px;
  }
}

.selection-container {
  &.annotating-mode {
    user-select: none;
    cursor: crosshair;
  }

  &.text-selection-mode {
    cursor: text;

    &::-moz-selection {
      background: rgba(0, 0, 255, 0.2);
      color: transparent;
    }

    &::selection {
      background: rgba(0, 0, 255, 0.2);
      color: transparent;
    }
  }

  &.normal-mode {
    cursor: default;
  }

  .selection__rectangle {
    opacity: 0.3;
    border: 1px dashed #000;
    position: absolute;
  }
}

.tooltip-container {
  position: relative;

  &:hover {
    .tooltip__text {
      visibility: visible;
    }
  }

  .tooltip__text {
    visibility: hidden;
    width: max-content;
    max-width: 200px;
    color: #fff;
    background-color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    top: 110%;
    left: 0;
    opacity: 0.65;
  }
}

.page {
  display: flex;
  justify-content: center;

  &:not(:last-child) {
    padding-bottom: 40px;
  }

  .page__container {
    position: relative;

    .page__number_item {
      position: absolute;
      top: 50%;
      left: 0;
      width: 10px;
      height: 10px;
      opacity: 0;
    }

    .cursor-text {
      z-index: 10;
      position: absolute;
      border-radius: 10px;
      padding: 3px 10px;
      font-family: sans-serif;
      overflow: hidden;
      white-space: nowrap;
    }

    .page__canvas-container {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }

    .page__text-layer-container {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      line-height: 1.2;

      .token-container {
        position: absolute;
        white-space: pre-wrap;
        cursor: inherit;
        transform-origin: 0 0;
        padding: 0;
      }
    }

    .area-annotation__container {
      position: absolute;

      .area-annotation__text-input {
        padding: 8px 12px;
        background-color: #ffffff;
        border: 1px solid #e1e4e8;
        border-radius: 2px;
        width: calc(100% - 22px);
        min-width: 100px;
        margin-bottom: 2px;

        &:focus {
          outline: 0;
          background-color: #fff;
          box-shadow: 0 0 0 2px #e8f0f0;
        }

        &.hidden {
          visibility: hidden;
        }
      }

      .area-annotation__mark {
        position: relative;
        cursor: pointer;
        background-color: transparent;

        .area-annotation__toggle-icon {
          position: absolute;
          top: -12px;
          left: -12px;
          width: 16px;
          height: 16px;
          cursor: pointer;
          background-color: #f7f7f7;
          border-radius: 100%;
          padding: 2px;
          z-index: 1;
          border: 1px solid rgba(0, 0, 0, 0.3);
          opacity: 0.8;
        }

        span {
          width: 100%;
          height: 100%;
          display: block;
          opacity: 0.2;
        }
      }
    }


    .table-builder__container {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;

      .table-builder__border {
        position: absolute;

        .table-builder__border-icon {
          cursor: pointer;
          visibility: hidden;
          position: absolute;
          background-color: #fff;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          font-size: 20px;
          border: 3px solid #69c0ff;
          z-index: 11;


          &:hover {
            visibility: visible;
          }
        }

        &:hover {
          & > .table-builder__border-icon {
            visibility: visible;
          }
        }

        .link-table-button__container {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          border: 3px solid #69c0ff;
          background: white;
          padding: 2px;
          border-radius: 50%;

          &:active {
            transform: scale(0.95);
          }

          &:after {
            top: 20px;
            left: calc(50% - 2px);
            width: 0;
            content: '';
            position: absolute;
            display: block;
            height: 10px;
            border: 2px solid #69c0ff;
          }

          .link-table-button__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
          }
        }
      }

      .table-builder__border-trash-icon {
        position: absolute;
        border-radius: 50%;
        background-color: #f7f7f7;
        border: 1px solid rgba(0, 0, 0, 0.3);
        padding: 5px 6px;
        cursor: pointer;
      }


      .table-builder__table {
        position: absolute;
        display: grid;
        border-bottom: 3px solid #69c0ff;
        border-right: 3px solid #69c0ff;
      }

      .exclude-button__container {
        position: absolute;
        left: -32px;
        border-radius: 50%;
        background-color: #f7f7f7;
        border: 1px solid rgba(0, 0, 0, 0.3);
        padding: 2px 3px;
        cursor: pointer;
        display: flex;

        span {
          font-size: 12px !important;
        }

        &:active {
          transform: scale(0.95);
        }

        span {
          font-size: 8px;
        }
      }

      .excluded-row__container {
        position: absolute;
        opacity: 0.6;
        background: repeating-linear-gradient(
                        45deg,
                        #B0BEC5,
                        #B0BEC5 10px,
                        #78909C 10px,
                        #78909C 20px
        );
      }

      .col {
        border-right: 3px solid #69c0ff;
        cursor: col-resize;
        position: absolute;

        &.dragging {
          border-right: 5px solid #69c0ff;
        }
      }

      .row {
        border-bottom: 3px solid #69c0ff;
        cursor: row-resize;
        position: absolute;

        &.dragging {
          border-bottom: 5px solid #69c0ff;
        }
      }

      .table-line__remove-button {
        color: #69c0ff;
        position: absolute;
        z-index: 15;
        cursor: pointer;
      }
    }
  }
}

.table-data-overview__container {
  width: 100%;
  height: 200px;
  position: absolute;
  background-color: #323639;
  color: #fff;
  bottom: 0;
  z-index: 11;
  overflow-y: auto;

  h2 {
    padding: 0 50px;
  }

  .remove-line-icon {
    cursor: pointer;
  }

  table {
    width: 100%;
    padding: 10px;
  }

  td {
    text-align: center;
    vertical-align: middle;
  }

  .table-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #323639;
    position: sticky;
    bottom: 0;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 30px;
      cursor: pointer;
      border-radius: 50px;
      margin: 10px;
      border: 2px solid #767676;
      background: #22262f;
      color: #fff;


      &:hover {
        border: 2px solid #5c5c5c;
      }

      &:active {
        background: #0f1014;
      }
    }
  }
}
