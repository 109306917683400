.document-pill__container {
  display: flex;

  &.list-container {
    flex-direction: column;

    h5 {
      margin-bottom: 5px;
    }
  }

  .document-pill {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    white-space: nowrap;
    padding: 0 12px;
    border-radius: 50px;
    box-shadow: inset 0 0 0 1px rgba(100, 121, 143, 0.3);
    background-color: white;
    min-width: 33px;
    height: 33px;

    .document-pill__icon {
      margin-right: 8px;
      fill: #8e9ba6;
    }

    .document-pill__label {
      width: inherit;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .document-pill__label-overflow {
      font-size: 10px;
      font-weight: 700;
    }

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.document-pill-popover__title {
  width: 400px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}