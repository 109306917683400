@import "../../../app/App";

.welcome-page__container {
  h1 {
    text-align: center;
    margin-bottom: 10px;
    font-weight: $font-weight-semi-bold;
  }

  .welcome-page__form {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      text-align: center;
    }

    .welcome-page__form-item {
      display: flex;
      flex-direction: column;
      gap: 5px;

      input {
        height: 40px;
      }
    }

    .sign-in-button {
      width: 100%;
      color: $secondary-color;
      font-size: 15px;

      .sign-in-button__icon {
        font-size: 17px;
      }

      &:hover {
        color: $white-color;
        background-color: $secondary-color !important;
      }
    }
  }
}