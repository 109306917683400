@import "../../../app/App";

.drawer-container {
  width: $transition-view-width !important;
  background-color: $dark-grey-color !important;
  position: absolute;

  .ant-drawer-header {
    border-bottom: 1px solid $extra-dark-grey-color;
  }
}