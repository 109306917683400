@import "../app/App";

.pdf-viewer__container {
  height: calc(100vh - $layout-header-height);
  width: calc(100vw - $transition-view-width - $side-bar-width);

  .annotator-container {
    background-color: $annotator-background;
    position: relative;
    height: inherit;
    max-height: inherit;
    box-sizing: border-box;

    .annotator-pages-container {
      height: 100%;
      overflow-y: auto;

      .annotator-pages {
        padding-bottom: 50px;
      }
    }

    .table-builder__border-icon {
      width: auto !important;
      height: auto !important;
    }
  }

  .controls__container {
    z-index: 20;
    background-color: $annotator-background;
    border-bottom: 1px $white-color solid;
    margin-bottom: 15px;
  }
}