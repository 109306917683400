@import "../../app/App";

$btn-padding-primary-default: 10px 16px;
$btn-padding-rejected: 15px 18px;
$btn-padding-approved-show-details: 15px;

.ant-btn {
  &.btn-focused {
    outline: 5px solid $primary-color !important;
  }

  &.ant-btn-primary {
    &.btn-focused {
      outline: 5px solid $white-color !important;
    }

    &:hover {
      color: $white-color;
      background-color: $secondary-color;
      border-color: $secondary-color;
    }

    &.btn-approve {
      &.btn-focused {
        outline: 5px solid $primary-color !important;
        transition: none;
      }

      color: $dark-grey-color;
      background-color: $white-color;
      transition: all 0.33s ease-out;

      &:hover {
        color: $white-color;
        background-color: $dark-grey-color;
        border: 1px solid $white-color;
        transition: all 0.33s ease-in;
      }
    }
  }

  &.ant-btn-default {
    &.btn-focused {
      outline: 5px solid $primary-color !important;
    }

    &.ant-btn-round {
      &:hover {
        color: $secondary-color;
        background-color: $white-color;
        border-color: $secondary-color;
      }
    }

    &.btn-show-details {
      color: $white-color;
      background-color: $dark-grey-color;
      border-color: $white-color;
      display: flex;
      flex-direction: row;
      justify-content: center;

      svg {
        margin-left: $margin-xs;

        path {
          transition: fill 0.33s ease-out;
          fill: $white-color;
        }
      }

      &:hover {
        color: $dark-grey-color;
        background-color: $white-color;
        border-color: $dark-grey-color;

        svg {
          path {
            transition: fill 0.33s ease-in;
            fill: $black-color;
          }
        }
      }
    }

    &.btn-reject {
      color: $white-color;
      background-color: $dark-grey-color;
      border: 1px solid $white-color;
      transition: background-color, color 0.33s ease-out;

      &:hover {
        color: $dark-grey-color;
        background-color: $white-color;
        transition: color, background-color 0.33s ease-in;
      }
    }
  }

  &.ant-btn-danger {
    &.btn-rejected {
      padding: $btn-padding-rejected;
    }
  }

  &.ant-btn-primary,
  &.ant-btn-default,
  &.ant-btn-danger {
    height: auto;
    padding: $btn-padding-primary-default;

    &.btn-approved,
    &.btn-show-details,
    &.btn-approve,
    &.btn-reject {
      padding: $btn-padding-approved-show-details;
    }

    &.btn-approved,
    &.btn-approve,
    &.btn-reject,
    &.btn-rejected {
      font-weight: $font-weight-semi-bold;
    }

    &.btn-show-details,
    &.btn-approve,
    &.btn-reject,
    &.btn-rejected {
      font-weight: $font-weight-regular;
    }
  }
}
