.average-number__container {
  padding: 20px 10px;
  overflow-y: auto;

  h3 {
    margin-bottom: 20px;
    white-space:nowrap;
    overflow:hidden;
  }

  .average-number__list {
    display: inline-flex;
    flex-direction: column;
    gap: 10px;

    .average-number__item {
      display: inline-flex;
      flex-direction: column;
      padding: 5px;
      align-items: center;

      .average-number__item-key {
        font-weight: 300;
        font-size: 12px;
        text-align: center;
        color: rgba(0, 0, 0, 0.45);
      }

      .average-number__item-value {
        font-weight: 700;
        font-size: 24px;
      }
    }
  }
}