.list__item {
  cursor: pointer;
  padding: 10px;
}

.user-management-modal__popover {
  width: 200px;
  //padding-top: 0;

  .ant-popover-content {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
}

.user-management-modal__container {
  .user-management-modal__title {
    padding: 10px 0;
    display: flex;
    gap: 10px;
    align-items: center;

    h2 {
      margin: 0;
    }

    span {
      margin-top: 5px;
    }
  }

  .header-accent__container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
    }

    .ant-btn {
      width: 200px;
    }
  }

  .user-management-modal__delete-button {
    color: #ff4d4f;
    cursor: pointer;

    &:active  {
      transform: scale(0.90);
    }
  }

  .user-management-modal__alert {
    padding: 10px 15px;
    margin-bottom: 15px;
  }

  .header-accent-permissions__container {
    margin-bottom: 10px;
  }

  .user-management-modal__table {
    margin-bottom: 20px;
  }

  .user-management-modal-permissions__select {
    margin-bottom: 30px;
  }
}