@import "../../app/App";

$done-status-color: $primary-color;
$ongoing-status-color: $secondary-color;
$todo-status-color: $dark-grey-color;

$pill-label-line-height-default: $line-height-base;
$pill-label-height: 33px;
$pill-label-border-radius: calc($pill-label-height / 2);

.pill-label {
  height: $pill-label-height;
  display: inline-block;
  text-align: center;
  color: $white-color;
  font-weight: $font-weight-light;
  line-height: calc($pill-label-height - 1px);
  padding: 0 $padding-xs;
  border-radius: $pill-label-border-radius;
}

.done {
  color: $dark-grey-color;
  background-color: $done-status-color;
}

.ongoing {
  background-color: $ongoing-status-color;
}

.to-do {
  background-color: $todo-status-color;
}

.rejected {
  background-color: $btn-danger-bg;
}

.processing {
  background-color: #4096ff;
}
