.error__alert-warning {
  .error-title-container {
    display: flex;
    align-items: center;

    i {
      font-size: 25px;
      margin-right: 10px;
    }
  }

  .error-description {
    margin-bottom: 10px;
  }

  .error-more-details-container {
    max-height: 100px;
    overflow-y: auto;

    .error-more-details-line {
      margin: 0;
    }
  }
}