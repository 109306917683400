.skeleton-metrics__container {
  padding: 10px 20px;
  width: 650px;
  margin-bottom: 10px;

  .skeleton-metrics__title {
    width: 250px;
    margin-bottom: 30px;
  }

  .skeleton-metrics__sections {
    display: flex;
    flex-direction: row;
    width: 600px;
    height: 150px;

    .skeleton-metrics__section {
      display: flex;
      flex-direction: column;
      width: 33%;

      .skeleton-metrics__sections-header {
        width: 50%;
        margin-bottom: 10px;
      }

      .skeleton-metrics__sections-value {
        width: 50%;
        margin-bottom: 10px;
      }

      .skeleton-metrics__sections-progress {
        margin-left: 10%;
        width: 30%;
      }
    }
  }
}