@import "../../../../app/App";

.ant-avatar.assignee-avatar {
  background-color: $primary-color;

  .ant-avatar-string {
    color: $black-color;
    font-weight: $font-weight-semi-bold;
  }
}
