@import "../../app/App";

.ant-tour-inner {
  border: 1px solid $primary-color !important;

  .ant-tour-close {
    cursor: pointer;
  }

  .ant-tour-description {
    margin-top: 15px;
  }
}