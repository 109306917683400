@import "../../../app/App";

.sources-overview__container {
  padding: 10px 20px;
  margin-bottom: 50px;

  .sources-overview__header {
    display: inline-flex;
    align-items: center;
    gap: 10px;
  }

  .sources-overview__data-container {
    margin: 25px 0;

    .sources-overview__header-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      margin-bottom: 15px;
      color: $medium-dark-grey-color;
      font-weight: 500;
    }

    .sources-overview__source-item {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(4, 1fr);
      margin-bottom: 15px;
      transition: transform 500ms;
      padding: 2px 8px;


      &:hover {
        transform: scale(1.02);
        background-color: $white-color;
        border-radius: 10px;
        padding: 2px 8px;
      }

      .sources-overview__source-title {
        font-size: 20px;
        font-weight: 900;
        cursor: pointer;
      }

      .sources-overview__source-item-double {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        width: 100px;

        .main {
          font-size: 20px;
          font-weight: 900;
          color: $dark-grey-color;
        }

        .sources-overview__source-item-trend {
          font-size: 12px;
          font-weight: 500;
        }
      }

      .sources-overview__source-item-trend {
        &.positive {
          color: $success-color;

          &:after {
            content: '% ↑';
          }
        }

        &.positive-inverse {
          color: $success-color;

          &:after {
            content: '% ↓';
          }
        }

        &.negative {
          color: $error-color;

          &:after {
            content: '% ↓';
          }
        }

        &.negative-inverse {
          color: $error-color;

          &:after {
            content: '% ↑';
          }
        }

        &.neutral {
          color: $medium-dark-grey-color;
          &:after {
            content: '%';
          }
        }
      }

      .source-distribution__container {
        width: 250px;
        height: 20px;
        border-radius: 5px;
        display: flex;
        overflow: hidden;
        background-color: #f0f0f0;
      }
    }
  }
}