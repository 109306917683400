@import "../../../app/App";

.role-icons__container {
  display: flex;
  gap: 10px;

  .role__icon {
    font-size: 16px;
    font-weight: 900;
    color: $secondary-color;
  }
}