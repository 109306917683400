.title-line {
  width: 30%;
}

.filters-line {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 10px;
}

.graph-lines__container {
  margin-top: 60px;

  .graph-margin-top {
    margin-top: 20px;
  }

  .graph-line-width {
    width: 100%;
  }
}