.stp-supplier-footer-add__container {
  display: flex;
  .stp-supplier-footer-add__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .stp-supplier-footer-add__spacer {
    width: 75px;
  }
}