@import "../../../../app/App";

.ant-tooltip {
  .error-status, .invalid {
    color: $error-color;
  }

  .warning-status, .requires-attention {
    background-color: $warning-color;
  }

  .info-status {
    background-color: $info-color;
  }

  .validation-message {
    margin-bottom: 15px;
  }
}

.multiple-groupblock {
  height: 100%;

  &.list-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .list-item {
      display: flex;
      align-items: center;
      color: $white-color;
      cursor: pointer;
      width: 100%;
    }

    .ant-list-item-meta-avatar {
      margin-right: 5px !important;
    }

    .list-item-description {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .entity-accuracy-score {
        color: $normal-grey-color;
        margin-left: 5px;
        margin-right: 5px;
        font-size: $font-size-xs;
        font-style: italic;

        &.requires-attention {
          color: $warning-color;
        }

        &.hidden {
          display: none;
        }

        .attention-required__icon {
          margin-right: 2px;
        }
      }

      .invalid-icon {
        color: $error-color;
      }

      i.remove {
        opacity: 0;
        color: $error-color;
      }

      &:hover {
        i.remove {
          transition: opacity 0.3s ease-in;
          opacity: 1
        }
      }

      .loader {
        margin-left: 5px;
      }
    }
  }

  .multiple-annotations-group-block {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    .list-item-meta {
      display: flex;
      flex-direction: row;
      flex: 1 1;
      align-items: center;

      .ant-list-item-meta-avatar {
        margin-right: 5px;
      }
    }

    .error-text {
      font-size: $font-size-sm;
      font-weight: $font-weight-light;
      color: $error-color;
      margin-top: $margin-xs;
      margin-left: calc($margin-lg * 3 - $margin-xs);
      padding-right: calc($padding-xl * 3);
    }
  }

  .edit-entity {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    min-height: 25px;
    align-items: center;

    input {
      border-color: $primary-color !important;
      color: $white-color !important;
      height: 23px;
    }

    .ant-input-number.ant-input-number-sm {
      background-color: transparent;

      .ant-input-number-handler-wrap {
        border: transparent;
        display: none;

        .ant-input-number-input {
          background-color: transparent;

          input {
            background-color: transparent;
          }
        }

        .ant-input-number-handler {
          background-color: $dark-grey-color;
          border: transparent;
        }
      }

      .ant-input-number-input-wrap {
        color: $white-color;
      }
    }

    .date-picker-wrapper {
      width: 100%;

      .ant-picker {
        .ant-picker-input {
          height: 22px;

          input {
            font-size: $font-size-xs;
            color: $white-color;
          }

          span.ant-picker-clear {
            background-color: $dark-grey-color;
          }

          span.ant-picker-suffix {
            background-color: transparent;

            svg {
              path {
                fill: $white-color;
              }
            }
          }
        }
      }
    }

    .ant-input,
    .ant-input-number.ant-input-number-sm,
    .ant-picker {
      width: 70%;
      font-size: $font-size-xs;
      background-color: transparent;
      color: $white-color;
    }

    .btn-approve-entity {
      margin-left: $margin-xs;
    }

    .edit-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: -30px;
      align-items: center;
      margin-right: 10px;

      i.cancel,
      i.submit {
        padding: 10px 5px;
        transition: opacity 0.2s ease-in;
        color: $group-block-list-item-text-color;
        opacity: 1;
        font-size: 20px;
      }

      i.cancel {
        margin-right: 5px;

        width: 18px;
        height: 18px;

        &:hover {
          color: $btn-danger-bg;
        }
      }

      i.submit {
        width: 20px;
        height: 20px;

        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .entity-visual__container {
    display: flex;
    flex-direction: row;
    height: 20px;
    border-radius: 3px;
    align-items: center;

    .entity-visual__text {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $white-color;
    }

    .requires-attention {
      color: $warning-color;
    }

    .error-status, .invalid {
      color: $error-color;
    }

    .entity-visual__normalized-icon {
      color: $primary-color;
      font-size: $font-size-base;
    }

    .entity-visual__element_labeled {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 320px;
      margin-top: 2px;
      font-weight: $font-weight-semi-bold;
    }

    .entity-visual__seperator {
      font-size: 14px;
      margin: 0 5px;
    }
  }

  .hover-icon {
    &:active {
      transform: scale(0.95);
    }
  }
}

