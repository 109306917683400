@import "../../../app/App";

.group-block-tabs-container {
  display: flex;
  height: 35px;
  width: 100%;
  justify-content: space-between;
  border-top: 2px solid $dark-grey-color;

  .group-block__flex-container {
    display: flex;
  }

  .group-block__resize-icon {
    position: fixed;
    left: 50%;
    display: flex;
    align-items: center;
    font-size: $font-size-md;
    padding: 5px;
    cursor: grab;
    color: $white-color;

    &:active {
      cursor: grabbing;
    }
  }

  .group-block-tab-button-container {
    display: block;

    .annotation-table-controls-resizer {
      height: 5px;
      background: $annotator-background;
      cursor: ns-resize;
    }

    .group-block-tab-button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
      background-color: $annotator-background !important;
      border-bottom-color: $secondary-color;
      border: none;
      width: 100%;
      padding: 2px 20px;
      height: 28px;
      border-bottom: 2px solid transparent;

      .group-block-name {
        color: $group-block-list-item-text-color;
        font-size: 16px;
        text-transform: uppercase;
      }

      &.active {
        border-bottom: 2px solid $primary-color;

        .group-block-name {
          color: $primary-color !important;
        }
      }

      &.has-multiple:hover {
        border-bottom: 2px solid transparent;

        .group-block-name {
          color: $primary-color !important;
        }

        &.active {
          border-bottom: 2px solid $primary-color;
        }
      }

      &::after {
        all: unset;
      }
    }
  }

  .collapse-or-expand-container {
    color: #cfcece;
    font-size: $font-size-large;
    padding-left: 3px;
    padding-right: 3px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $normal-grey-color;

    i:hover {
      color: $white-color;
    }

    i:active {
      transform: scale(0.95);
    }
  }
}