@import "../../../../app/App";

.graph__container {
  padding: 20px 10px;
  margin-bottom: 15px;
  position: relative;

  .graph__header {
    display: inline-flex;
    align-items: center;
    gap: 10px;
  }

  .graph-loader {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
  }

  .g2-tooltip {
    .tooltip__title {
      margin-bottom: 12px;
      margin-top: 12px;
    }

    .tooltip__items {
      margin: 0;
      list-style-type: none;
      padding: 0;

      .tooltip__item {
        list-style-type: none;
        padding: 0;
        margin: 12px 0;

        .tooltip__item-color {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 8px;
        }

        .tooltip__item-value {
          float: right;
          margin-left: 30px;
        }
      }
    }
  }
}