@import "../../../app/App";

.controls__container {
  position: sticky;
  top: 0;
  height: 60px;
  width: 100%;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.09), 0 4px 8px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.3), 0 2px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  gap: 10px;
  z-index: 20;
  background-color: $annotator-background;
  border-bottom: 1px $white-color solid;
  margin-bottom: 15px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .controls__page-container {
    display: flex;
    align-items: center;
    gap: 5px;

    input {
      color: #fff;
      background-color: #191b1c;
      border: none;
      height: 20px;
      width: 20px;
      text-align: center;
    }
  }

  .controls__size-container {
    display: flex;
    align-items: center;
    gap: 15px;

    input {
      background-color: #fff !important;
      color: #191b1c !important;
      border: none;
      height: 20px;
      width: 30px;
      text-align: center;
    }
  }

  .controls__divider {
    width: 19px;
    height: 0;
    border: 1px solid #6E798C;
    transform: rotate(-90deg);
  }

  .controls__table-container {
    display: flex;
  }

  .controls__button {
    padding: 9px 10px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .controls__button-icon {
      color: #fff;
    }

    &:hover {
      background-color: #424649;
    }

    &:active {
      background-color: #191b1c;
    }

    &.active {
      background-color: #191b1c;
    }

    &.disabled {
      cursor: not-allowed;

      .controls__button-icon {
        opacity: 0.2;
        color: #fff;
      }
    }
  }
}