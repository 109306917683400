@import "../../../app/App";

.actions-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;

  .delete-all-lines-icon {
    cursor: pointer;
    color: $table-line-action-color;
    font-size: 15px;

    &:active {
      transform: scale(0.95);
    }

    &:hover {
      transition: color 0.3s ease-in;
      color: $btn-danger-bg;
    }
  }
}