@import "../../app/App";

.header-export__container {
  display: flex;
  justify-content: center;

  .export__icon {
    color: $white-color;
    font-size: $font-size-md;
  }
}