@import "../../../../app/App";

.group-blocks-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;

  h2 {
    font-size: $font-size-base;
    line-height: $font-size-base;
    margin-right: 10px;
    margin-bottom: 0;
    color: $white-color;
  }

  .loading {
    margin: 0 $margin-xs;
  }
}
