@import "../../../app/App";

.issues-overview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;

  .issues-overview-tag {
    font-size: 15px;
    cursor: pointer;

    i {
      margin-left: $margin-xss;
    }
  }
}

.issues-overview-popover-title {
  margin-right: $margin-xs;
  display: inline-block;
}