@import "../../../app/App";

.organization-invites-modal__container {
  .organization-invites-modal__title {
    padding: 10px 0;
    display: flex;
    gap: 10px;
    align-items: center;

    h2 {
      margin: 0;
    }

    span {
      margin-top: 5px;
    }
  }

  .organization-invites-modal__table {
    margin-bottom: 20px;

    .delete-invite-icon {
      cursor: pointer;

      &:hover {
        color: $btn-danger-bg;
      }
    }
  }
}