@import "../../app/App";

.overview__container {
  height: 100%;
  display: flex;
  flex-flow: column;

  .table-overview__container {
    flex: 0 1 auto;
  }

  .ant-table-header {
    background-color: $dark-grey-color;
  }

  .ant-table-row {
    height: 75px;
  }

  .table-overview__container .ant-table-thead .ant-table-cell, .table-overview__container thead.ant-table-thead {
    background-color: $dark-grey-color !important;
    color: $white-color !important;
    height: $table-header;
  }

  .table-overview__container .ant-table-cell::before {
    background-color: $normal-grey-color !important;
  }

  .table-overview__container .ant-table-cell-scrollbar::before {
    background-color: $dark-grey-color !important;
  }

  .overview-table__row * {
    cursor: pointer;
  }

  .ant-table-cell:has(.bulk-action-checkbox) {
    padding: 0 !important;
  }

  .ant-table-cell:has(.bulk-action-header__container) {
    padding: 0 !important;
    display: flex;
    justify-content: center;
  }

  .table-overview__container .ant-table-row {
    .bulk-action-checkbox {
      height: 74px;
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        cursor: pointer;
      }

      .hover {
        transition: opacity 0.3s ease-in;
        opacity: 0;
      }

      .visible {
        opacity: 1;
      }
    }

    .restore-icon {
      font-size: 17px;
      display: flex;
      justify-content: center;

      i {
        cursor: pointer;
      }
    }

    .delete-icon {
      margin-top: $padding-xss;
      width: 40px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      font-size: 14px;

      i {
        cursor: pointer;
        transition: opacity 0.3s ease-in;
        opacity: 0;
      }
    }

    &:hover {
      .bulk-action-checkbox {
        .hover {
          transition: opacity 0.3s ease-out;
          opacity: 1;
        }
      }

      .delete-icon {
        i {
          transition: opacity 0.3s ease-out;
          opacity: 1;

          &:hover {
            color: $error-color;
          }
        }

        svg.alert-circle {
          transition: stroke 0.3s ease-out;
          stroke: $error-color;
        }
      }
    }
  }

  .overview-table__row-disabled * {
    cursor: not-allowed;
  }

  .overview-table__header-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 5px;

    .cell-title {
      display: flex;
      align-items: center;

      .title-icon {
        margin-right: $margin-xs;
        opacity: 0.4;
        font-size: medium;
      }
    }
  }
}

.overview-skeleton__container {
  height: 100%;
  display: flex;
  flex-flow: column;

  .ant-table-header {
    background-color: $dark-grey-color;
  }

  .table-overview-skeleton__container .ant-table-thead .ant-table-cell, .table-overview__container thead.ant-table-thead {
    background-color: $dark-grey-color !important;
    color: $white-color !important;
    height: $table-header;
  }

  .table-overview-skeleton__container .ant-table-cell::before {
    background-color: $normal-grey-color !important;
  }

  .table-overview-skeleton__placeholder {
    flex: 1 auto;
    background-color: $overview-background-grey-color !important;
  }


  .ant-table-row {
    height: 75px;
  }

  .overview-table__header-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 5px;

    .cell-title {
      display: flex;
      align-items: center;

      .title-icon {
        margin-right: $margin-xs;
        opacity: 0.4;
        font-size: medium;
      }
    }
  }
}