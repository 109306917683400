@import "../../../../app/App";

.issues-popover-container {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;

  .issues-group-block-container {
    margin-bottom: $margin-xs;

    .score-attention-required-container {
      margin-left: $padding-sm;
    }

    .warning-icon {
      margin-right: $padding-xs;
      color: $warning-color;
    }

    .error-icon {
      margin-right: $padding-xs;
      color: $error-color;
    }

    .issues-group-block-name {
      font-size: 15px;
      text-transform: uppercase;
      margin-bottom: $margin-xss;
    }

    .issues-group-block-type {
      font-size: $font-size-sm;
      margin-left: $margin-xs;
      font-weight: bold;
    }

    .issues-group-block-line {
      padding: 0 $padding-xss;
      margin-bottom: $padding-xs;
      margin-left: $padding-xss;
      cursor: pointer;

      .name:hover {
        color: $primary-color !important;
      }
    }
  }
}
