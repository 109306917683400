.password-change__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.password-change__container-editing {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .password-change__button-group {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }

  .password-change__error {
    font-size: 10px;
    font-weight: 200;
    font-style: italic;
    color: red;
  }

  .password-validation__list {
    padding: 10px 0;
    list-style: none;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      padding-bottom: 5px;

      label {
        margin-left: 5px;
      }
    }

    .error {
      color: red;
    }

    .valid {
      color: green;
    }
  }
}
