@import "../../../app/App";

$disabled-color: #f5f5f5;
$disabled-no-parent-value-color: #b6b5b5;


.classifier-block__container {
  margin-top: 10px;
  margin-bottom: 25px;
  padding: 0 16px;

  .classifier-block__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 18px;
      color: #888888;
      text-transform: uppercase;
      margin: 0;
    }

    i {
      color: #888888;
      font-weight: 700;
      font-size: 20px;
      cursor: pointer;

      &:active {
        transform: scale(0.90);
      }
    }
  }

  .classifier-block__items {
    transition: height 1s;

    .classifier-item__container {
      display: flex;
      flex-direction: column;
      width: 100%;

      .categorization-derived__container {
        display: flex;
        flex-direction: row;
        width: 100%;

        .categorization-derived__select {
          width: 90%;
        }

        .categorization-derived__button {
          width: 10%;
          height: 32px;

          i {
            font-size: 20px;
          }

          &.disabled {
            cursor: not-allowed;
            background-color: $disabled-color !important;
          }
        }
      }


      .classifier-item__select-is-incomplete {
        border: 3px solid $error-color;
        border-radius: 9px;
      }

      .classifier-item__select-disabled {
        .ant-select-selector {
          background-color: $disabled-color !important;
        }
      }

      .classifier-item__select-no-parent-value {
        .ant-select-selector {
          background-color: $disabled-no-parent-value-color !important;
          opacity: 0.5;
        }
      }

      .classifier-item__select-disabled .ant-select-selection-item {
        color: black;
        opacity: 0.5;
      }

      .classifier-item__data-wrapper {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 10px;

        .classifier-item__color-block {
          display: inline-block;
          height: 15px;
          min-width: 15px;
          border-radius: 100px;

          &.hidden {
            display: none;
          }
        }

        .classifier-item__label-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;

          label {
            color: #9a9a9a;
            font-size: 14px;
          }

          .classifier-item__visible-value {
            font-style: italic;
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

.select__empty-state {
  display: flex;
  justify-content: center;
  color: #888888;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}