.selector-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .selector-label {
    margin: 0 8px 0 0;
    line-height: 22px;
    font-weight: 700;
  }

  .selector {
    width: 200px;
  }
}