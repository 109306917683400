@import "../../../app/App";

.two-side-page__container {
  font-family: $font-family;

  h1,
  h3,
  label {
    color: $dark-grey-color;
  }

  .left-side {
    height: 100vh;
    width: 50%;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $secondary-color;

    .left-side-content {
      text-align: center;

      .klassifai-logo {
        height: $height-lg;
      }

      h1.banner-text {
        color: $white-color;
        line-height: $line-height-lg;
        text-align: center;
        margin: $margin-md;
        white-space: pre-line;
        font-weight: $font-weight-regular;
        max-width: 600px;
      }
    }
  }

  .right-side {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}