.filter-popover {
  .ant-popover-content {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

    .ant-popover-inner {
      padding: 10px !important;
    }
  }

  .filter-popover__container {
    .filter-popover__item {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding-bottom: 20px;
      min-width: 350px;
      max-width: 350px;

      &.hidden {
        display: none;
      }

      .filter-popover__label {
        i {
          margin-left: 5px;
        }
      }

      .filter-popover__select {
        width: 100%;
      }

      .filter-popover__input {
        height: 32px;
      }

      .filter-popover__range-picker {
        height: 32px;
      }
    }
  }

  .filter-popover__checkbox-container {
    margin-top: 10px;
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
  }
}
