@import "../../App";

.document-table td{
  border: 1px solid #000;
  padding: 0.25em 1em;
}

.exclude-button__container {
  padding: 2px 5px !important;
  left: -35px !important;
}

.table-data-overview__container {
  h2 {
    color: #ffffff;
    padding: 20px 0 10px 50px;
  }
}

.detail-content {
  .unsupported-file-type {
    background-color: #2c3038;
    height: calc(100vh - 64px);
    width: calc(100vw - 565px);
    padding: 50px;
    overflow-y: scroll;

    .unsupported-file-type__page {
      background-color: white;
      height: 100%;
    }
  }

  .controls__container {
    background-color: $annotator-background;
    border-bottom: 1px $white-color solid;
    margin-bottom: 15px;
  }
}