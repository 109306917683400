.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 25px;
    height: 45px;

    h4 {
      font-size: 18px;
      color: #888888;
      text-transform: uppercase;
      margin: 0;
    }
  }
}