.copyable-cell__container {
  display: flex;

  .copyable-cell__label {
    white-space: nowrap;
    width: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 5px;
  }

  .copyable-cell__icon {
    cursor: pointer;
  }
}
