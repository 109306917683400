@import "../../../app/App";

.metrics-overview__container {
  padding: 10px 20px;
  margin-bottom: 15px;

  .metrics-overview__header {
    display: inline-flex;
    align-items: center;
    gap: 10px;
  }

  .metrics-overview__data-container {
    display: flex;
    gap: 100px;
    margin-top: 25px;

    .metrics-overview__item {
      display: inline-flex;
      flex-direction: column;
      align-items: center;

      .metrics-overview__item-title {
        color: $medium-dark-grey-color;
        font-size: 14px;
        font-weight: 500;
      }

      .metrics-overview__item-value {
        font-size: 32px;
        font-weight: 700;
        color: $dark-grey-color
      }

      .metrics-overview__item-trend {
        font-size: 12px;
        font-weight: 500;

        &.positive {
          color: $success-color;

          &:after {
            content: '% ↑';
          }
        }

        &.positive-inverse {
          color: $success-color;

          &:after {
            content: '% ↓';
          }
        }

        &.negative {
          color: $error-color;

          &:after {
            content: '% ↓';
          }
        }

        &.negative-inverse {
          color: $error-color;

          &:after {
            content: '% ↑';
          }
        }

        &.neutral {
          color: $medium-dark-grey-color;

          &:after {
            content: '%';
          }
        }
      }
    }
  }
}