@import "../../app/App";

.annotation-table-controls-skeleton {
  background-color: $dark-grey-color !important;
  height: 100%;

  .header-skeleton {
    background-color: $extra-dark-grey-color !important;
    height: 30px;
  }
  .body-skeleton {
    padding: $padding-md $padding-sm;
  }
}

.annotation-table-controls-container {
  height: 100%;
  background-color: $dark-grey-color !important;

  .ant-table-tbody > tr > td {
    border-color: $normal-grey-color !important;
  }

  .group-blocks-container {
    flex: 1;
    background-color: $dark-grey-color !important;

    .group-block-table {
      height: 100%;
      background-color: $extra-dark-grey-color !important;

      .ant-table, .ant-table-cell-scrollbar {
        border-radius: 0 !important;
        background-color: $medium-dark-grey-color;
      }

      .ant-table-thead .ant-table-cell, thead.ant-table-thead {
        background-color: $medium-dark-grey-color !important;
        color: #9a9a9a !important;
      }

      .ant-table-header, .ant-table-thead {
        background-color: $dark-grey-color !important;
        height: 30px !important;
        overflow-y: scroll !important;

        th.ant-table-cell {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          font-size: 13px;
        }

        th.ant-table-cell:has(.actions-container) {
          right: 0 !important;
        }
      }

      .ant-table-container, .ant-table-row, td {
        &.highlighted-row {
          td {
            background-color: $extra-dark-grey-color !important;
          }
        }

        background-color: $dark-grey-color !important;
      }

      .index-column {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;

        .index-column-text {
          padding: 0 10px;
        }
      }

      .loading-row {
        width: 100%;

        .skeleton-loader {
          height: 10px !important;
        }

        .ant-skeleton-content {
          .ant-skeleton-title {
            height: 13px !important;

            background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.16) 25%, rgba(0, 0, 0, 0.26) 37%, rgba(0, 0, 0, 0.16) 63%) !important;
          }
        }
      }
    }

    .ant-table-tbody {
      .ant-table-cell, .ant-table-row {
        padding: 0 !important;
        margin: 0 !important;
      }

      li {
        padding: 2px !important;
      }
    }
  }
}