@import "../app/App";

.transition-view {
  overflow-y: hidden;
  height: 100%;
  width: $transition-view-width;
  min-width: $transition-view-width;
  background-color: $dark-grey-color;

  .transition-view-header {
    height: $transition-view-header-height;
  }

  .error__alert-warning {
    .warning__icon {
      color: $warning-color;
    }

    .error__icon {
      color: $error-color;
    }
  }

  div.horizontal-rule {
    background-color: $black-color;
    width: 100%;
    height: 1px;
  }

  .transition-view-footer {
    height: 100px;
    padding: $padding-md $padding-md 0 $padding-md;
  }
}
