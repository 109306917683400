.text-cell__container {
  display: flex;

  .text-cell__label {
    white-space: nowrap;
    width: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
