@import "../../../app/App";

.container {
  height: 100%;

  .csv-viewer__container {
    background-color: $annotator-background;
    height: calc(100vh - $layout-header-height);
    width: calc(100vw - $transition-view-width - $side-bar-width);
    padding: 75px 50px 50px;
    overflow-y: scroll;

    .page__container {
      background-color: $white-color;
      padding: 30px;
      min-height: 100%;
    }
  }
}