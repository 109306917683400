@import "../../../app/App";

$error-highlight-color: rgb(255, 107, 108);
$warning-highlight-color: #ffc451;

.bulk-action-alert {
  display: flex;
  background-color: $medium-dark-grey-color;
  border-color: $normal-grey-color;
  padding-left: 25px;
  padding-right: 25px;
  user-select: none;

  .bulk-action-alert__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bulk-action-alert__description {
      color: white;
    }

    .bulk-action-alert__link {
      font-weight: bold;
      margin-right: $margin-md;
      margin-left: $margin-md;

      i {
        margin-right: $margin-xss;
      }

      &.delete-action {
        color: $error-color;

        &:hover {
          transition: color 0.3s ease;
          color: $error-highlight-color;
        }
      }

      &.reject-action {
        color: $warning-color;

        &:hover {
          color: $warning-highlight-color;
        }
      }
    }
  }
}