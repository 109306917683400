@import "../../app/App";

.user-management-popover__overlay {
  .ant-popover-content {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

    .ant-popover-inner {
      padding: 10px !important;
    }
  }
}

.user-management-popover__title {
  .user-management-popover__title-button {
    font-weight: 500;
    padding: 0;
  }
}

.user-management-popover__body {
  cursor: pointer;
  display: flex;
  gap: 5px;
  color: #d03c38;

  .user-management-popover__body-icon {
    cursor: inherit;
  }

  .user-management-popover__body-label {
    cursor: inherit;
  }
}

.user-management__container {
  display: flex;
  flex-flow: column;
  box-shadow: $box-shadow;
  overflow-y: auto;
  z-index: 1;
  color: $white-color;
  height: 100%;
  padding: 20px 20px;

  .open-invite-button {
    margin-right: 10px;
  }

  .column-header-container i {
    color: $white-color !important;
  }

  .user-management__placeholder {
    display: flex;
    background-color: $overview-background-grey-color;
    flex: 1 1 auto;
  }

  .ant-table-thead .ant-table-cell, thead.ant-table-thead {
    background-color: $dark-grey-color !important;
    color: $white-color !important;
    border-radius: 0;
    height: $table-header;
  }

  .ant-table-cell::before {
    background-color: $normal-grey-color !important;
  }

  .ant-table-cell-scrollbar::before {
    background-color: $dark-grey-color !important;
  }

  .ant-table-row {
    height: 65px;
  }
  
  .user-management__table {
    tr {
      cursor: pointer;
    }

    .ant-table-header {
      height: 60px;
    }

    .ant-table-pagination {
      justify-content: center;
    }
  }

  h2 {
    padding-bottom: 30px;
    font-size: 38px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      line-height: 38px;
    }

    i {
      color: #2d3748;
      cursor: pointer;
    }
  }

  .user-status__container {
    display: flex;
    gap: 5px;
  }

  .header-filter__container {
    display: flex;
    justify-content: space-between;
  }
}

th {
  font-weight: 700 !important;
}