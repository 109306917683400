@import "../../../../../app/App";

$side-drawer-value: #dfdede;
$title-color: #9f9e9e;

.ant-drawer-header {
  color: $white-color;

  .anticon-close {
    color: $white-color;
  }
}

.content-container {
  h5 {
    font-size: $font-size-base;
    font-weight: 700;
  }

  .divider {
    background-color: $normal-grey-color;
  }

  .group-container {
    .group-label {
      font-size: $font-size-md;
      margin-bottom: $margin-md;
      color: $title-color;
      text-transform: uppercase;
    }

    .two-column-line {
      display: flex;
      flex-direction: row;

      .column {
        flex-direction: column;
        width: 50%;
        margin-bottom: $margin-md;
      }
    }

    .full-width-line {
      width: 100%;
      margin-bottom: $margin-md;
    }

    .label {
      color: $group-block-list-item-text-color;
      font-size: $font-size-base;
      margin-bottom: $margin-xss;
    }

    .value {
      width: 100%;
      font-style: normal;
      color: $white-color;
      font-size: $font-size-base;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: $margin-xss;
    }

    .value-email-content {
      background-color: $white-color;
      padding: 5px;
      border-radius: 2px;
      max-height: 400px;
      overflow-y: scroll;
    }
  }
}

.ant-drawer-content-wrapper {
  top: $layout-header-height !important;
}